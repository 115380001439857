import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BEMHelper from '@ynap/bem-helper';
import { Button } from '@ynap/button';
import { Input, ValidatedField } from '@ynap/field';
import { validation } from '@ynap/form-utils';
import { REGISTER_EMAIL_SUCCESS } from '../../../../../state/constants/actionTypes';
import { TickIcon } from '../../icons/jsx/index';

const bem = new BEMHelper('FastUserRegistration');

const validServerResponse = ({ type }) => type === REGISTER_EMAIL_SUCCESS;

const FastUserRegistration = ({ messages, register, multiShopCookie }) => {
    const {
        heading,
        description,
        subscribe,
        sucessfulEmailMessage,
        NoEmailMessage,
        invalidEmailMessage,
        placeholder,
        exclusionsText,
        exclusionsLink
    } = messages.fastUserRegistration;

    const [email, setEmail] = useState('');
    const [validationError, setValidationError] = useState('');
    const [hasRegistered, setHasRegistered] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const validateEmail = () => {
        if (validation.isEmpty(email)) {
            setValidationError(NoEmailMessage());
            return false;
        }
        if (!validation.isValidEmail(email)) {
            setValidationError(invalidEmailMessage());
            return false;
        }
        return true;
    };

    const handleSuccessfulSubmit = () => {
        setValidationError('');
        setHasRegistered(true);
        setTimeout(() => setShowConfirmation(true), 400);
    };

    const submitEmail = async () => {
        const gender = multiShopCookie === 'mens' ? 'Male' : 'Female';
        const response = await register(email, gender);
        validServerResponse(response) ? handleSuccessfulSubmit() : setValidationError(invalidEmailMessage());
    };

    const handleFastUserRegistration = e => {
        e.preventDefault();
        validateEmail() && submitEmail();
    };

    const handleInputChange = e => {
        setValidationError('');
        setEmail(e.target.value);
    };

    return (
        <div className={bem()}>
            <div className={bem('heading')}>
                <h2>{heading()}</h2>
                <p>
                    {description()}{' '}
                    {exclusionsText && (
                        <a className={bem('exclusionsLink')} href={exclusionsLink()} rel="noopener noreferrer">
                            {exclusionsText()}
                        </a>
                    )}
                </p>
            </div>
            <form className={bem('form')}>
                {showConfirmation ? (
                    <div className={bem('confirmation', 'fadeIn')}>
                        <TickIcon size="24" className={bem('icon')} />
                        <p>{sucessfulEmailMessage()}</p>
                    </div>
                ) : (
                    <div className={bem('entry', hasRegistered ? 'fadeOut' : '')}>
                        <ValidatedField error={validationError} className={bem('input')}>
                            <Input
                                name="fastUserRegistation"
                                data-testid="emailRegistration"
                                type="email"
                                placeholder={placeholder()}
                                onChange={handleInputChange}
                                value={email}
                                aria-label={heading()}
                            />
                        </ValidatedField>
                        <Button className={bem('submit')} type="secondary" onClick={handleFastUserRegistration}>
                            {subscribe()}
                        </Button>
                    </div>
                )}
            </form>
        </div>
    );
};

FastUserRegistration.propTypes = {
    messages: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    multiShopCookie: PropTypes.string,
    fastUserRegistration: PropTypes.object
};

export default FastUserRegistration;
