import React, { PureComponent, Fragment, useState } from 'react';
import BEMHelper from '@ynap/bem-helper';
import { getMatchingTags } from '@ycos/coremedia-renderer';
import PropTypes from 'prop-types';
import MenuSlide from '../../Utils/MenuSlide/MenuSlide';
import track from 'react-tracking';
import { trackSideNavFlyout, trackAccountLinkClick } from './analytics';
import { ContactPanelContainer, LocaleSwitchContainer } from '../Containers/Containers';
import CategoryMenu from './CategoryMenu/CategoryMenu';
import GenderSwitch from '../GenderSwitch/GenderSwitch';
import { OptimizelyFeature } from '@ynap/optimizely-utils';
import { Button } from '@ynap/button';

export const bem = new BEMHelper('Nav');

@track({
    category: {
        primaryCategory: 'site navigation',
        subCategory: 'nav'
    }
})
class Nav extends PureComponent {
    state = {
        open: false
    };

    @track((props, state) => (state.open ? null : trackSideNavFlyout()))
    handleClick = () => {
        this.setState({
            open: !this.state.open
        });
    };

    @track((props, state, [event]) => trackAccountLinkClick(event.currentTarget.getAttribute('data-target')))
    handleAccountLinkClick = (event, onClick) => {
        if (onClick) onClick(event);
    };

    checkVIP = user =>
        user?.response?.customerSegments?.customerCategory?.some(category =>
            ['VIP_GOLD_MARKETING', 'VIP_GOLD', 'VIP_PLATINUM'].includes(category.customerSegmentName)
        );

    renderMobileMenu = () => {
        const { messages, headerMenu, config, locale, logOut, isSignedIn, getProductCategoryUrl, reduced, multiShopCookie } = this.props;
        const { courtesyNav, nav } = messages;
        const { signIn, register } = nav;
        const menswearEnabled = config.multiShop?.enabled && !config.multiShop?.disableCountries?.includes(locale.country.toUpperCase());

        return (
            <Fragment>
                <div className={bem('icon', { reduced })} onClick={this.handleClick} />
                <MenuSlide handleClick={this.handleClick} toggle={this.state.open}>
                    <div className={bem('primary', 'mobile')}>
                        <div className={bem('title')}>{headerMenu.title}</div>
                        {!isSignedIn && (
                            <>
                                <button className={bem('close')} onClick={this.handleClick}>
                                    <i className={bem('close-icon')}></i>
                                </button>
                                <a
                                    className={bem('signRegCTA')}
                                    href={`/${locale.id}${nav.register.href()}`}
                                    onClick={event => this.handleAccountLinkClick(event)}
                                >
                                    <Button type={'primary'}>{`${signIn.text()} \\ ${register.text()}`}</Button>
                                </a>
                            </>
                        )}
                        {menswearEnabled && <GenderSwitch messages={messages} locale={locale.id} activeGender={multiShopCookie} componentType="header" />}
                        <ul className={bem('list')}>
                            {(headerMenu.items || []).map((item, index) => {
                                const menuTag = item.tags;
                                const isFrontRowTag = getMatchingTags(menuTag, 'frontrow');
                                const isVIP = this.checkVIP(this.props.user);

                                if (isFrontRowTag.length > 0 && !isVIP) return null;

                                if (isFrontRowTag.length > 0 && isSignedIn && isVIP) {
                                    return (
                                        <OptimizelyFeature feature={['web00170_vipmeganav_globalnav_mobile_exp']}>
                                            {props => {
                                                const { variables } = props;
                                                // Wait till Optimizely loads
                                                const [isOptimizelyLoading, setIsOptimizelyLoading] = useState(!!props?.optimizely);
                                                props?.optimizely?.onReady?.({ timeout: 2000 })?.then?.(() => {
                                                    setIsOptimizelyLoading(false);
                                                });
                                                const showVipMenu = !isOptimizelyLoading && variables.is_v1;
                                                return (
                                                    showVipMenu && (
                                                        <CategoryMenu
                                                            item={item}
                                                            key={`${item.title}-${index}`}
                                                            config={config}
                                                            getProductCategoryUrl={getProductCategoryUrl}
                                                            mobile={true}
                                                            FRMenuItemClass={'FR-menu-item'}
                                                        />
                                                    )
                                                );
                                            }}
                                        </OptimizelyFeature>
                                    );
                                }
                                return (
                                    <CategoryMenu
                                        item={item}
                                        key={`${item.title}-${index}`}
                                        config={config}
                                        getProductCategoryUrl={getProductCategoryUrl}
                                        mobile={true}
                                    />
                                );
                            })}
                        </ul>
                        <div className={bem('sections')}>
                            <div className={bem('localeSwitchMobile')}>
                                <LocaleSwitchContainer messages={messages} />
                            </div>
                            <ul className={bem('account-links')}>
                                <li key="customerService">
                                    <a href={`/${locale.id}${courtesyNav.customerServiceInfo.href()}`}>{courtesyNav.customerServiceInfo.text()}</a>
                                </li>
                                <li key="wishlist">
                                    <a href={`/${locale.id}${nav.wishlist.href()}`}>{nav.wishlist.text()}</a>
                                </li>
                                {isSignedIn ? (
                                    <Fragment>
                                        <li key="myAccount">
                                            <a
                                                href={`/${locale.id}${nav.myAccount.href()}`}
                                                onClick={event => this.handleAccountLinkClick(event)}
                                                data-target={nav.myAccountMobile.text()}
                                            >
                                                {nav.myAccountMobile.text()}
                                            </a>
                                        </li>
                                        <li key="myOrders">
                                            <a
                                                href={`/${locale.id}${courtesyNav.myOrders.href()}`}
                                                onClick={event => this.handleAccountLinkClick(event)}
                                                data-target={courtesyNav.myOrders.text()}
                                            >
                                                {courtesyNav.myOrders.text()}
                                            </a>
                                        </li>
                                        <li key="signOut">
                                            <a onClick={event => this.handleAccountLinkClick(event, logOut)} data-target={nav.signOut.text()}>
                                                {nav.signOut.text()}
                                            </a>
                                        </li>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <li key="track">
                                            <a
                                                href={`/${locale.id}${courtesyNav.trackOrder.href()}`}
                                                onClick={event => this.handleAccountLinkClick(event)}
                                                data-target={courtesyNav.trackOrder.text()}
                                            >
                                                {courtesyNav.trackOrder.text()}
                                            </a>
                                        </li>
                                        <li key="return">
                                            <a
                                                href={`/${locale.id}${courtesyNav.createReturn.href()}`}
                                                onClick={event => this.handleAccountLinkClick(event)}
                                                data-target={courtesyNav.createReturn.text()}
                                            >
                                                {courtesyNav.createReturn.text()}
                                            </a>
                                        </li>
                                    </Fragment>
                                )}
                            </ul>
                        </div>
                        <div className={bem('sections')}>
                            <ContactPanelContainer addClass={bem('contactus')} messages={messages} />
                        </div>
                    </div>
                </MenuSlide>
            </Fragment>
        );
    };

    onDesktopClick = () => {
        this.props.onInteraction && this.props.onInteraction();
    };

    renderDesktopMenu() {
        return (
            <div className={bem('primary', ['desktop', this.props.minimisedHeaderModifier])} onClick={this.onDesktopClick}>
                <div className={bem('hidden')}>Hello from London and Bologna</div>
                <ul className={bem('list')}>
                    {(this.props.headerMenu.items || []).map((item, index) => {
                        const menuTag = item.tags;
                        const isFrontRowTag = getMatchingTags(menuTag, 'frontrow');
                        const isVIP = this.checkVIP(this.props.user);

                        if (isFrontRowTag.length > 0 && !isVIP) return null;

                        if (isFrontRowTag.length > 0 && isVIP) {
                            return (
                                <OptimizelyFeature feature={['web00169_vipmeganav_globalnav_desktop_exp']}>
                                    {props => {
                                        const { variables } = props;
                                        // Wait till Optimizely loads
                                        const [isOptimizelyLoading, setIsOptimizelyLoading] = useState(!!props?.optimizely);
                                        props?.optimizely?.onReady?.({ timeout: 2000 })?.then?.(() => {
                                            setIsOptimizelyLoading(false);
                                        });
                                        const showVipMenu = !isOptimizelyLoading && variables.is_v1;
                                        return (
                                            showVipMenu && (
                                                <CategoryMenu
                                                    item={item}
                                                    minimisedHeaderModifier={this.props.minimisedHeaderModifier}
                                                    key={`${item.title}-${index}`}
                                                    messages={this.props.messages}
                                                    config={this.props.config}
                                                    imageBaseUrl={this.props.config.imageSphere.baseUrl}
                                                    desktop
                                                    getProductCategoryUrl={this.props.getProductCategoryUrl}
                                                    mobile={false}
                                                    user={this.props.user}
                                                    FRMenuItemClass={'FR-menu-item'}
                                                />
                                            )
                                        );
                                    }}
                                </OptimizelyFeature>
                            );
                        }
                        return (
                            <CategoryMenu
                                item={item}
                                minimisedHeaderModifier={this.props.minimisedHeaderModifier}
                                key={`${item.title}-${index}`}
                                messages={this.props.messages}
                                config={this.props.config}
                                imageBaseUrl={this.props.config.imageSphere.baseUrl}
                                desktop
                                getProductCategoryUrl={this.props.getProductCategoryUrl}
                                mobile={false}
                                user={this.props.user}
                            />
                        );
                    })}
                </ul>
            </div>
        );
    }

    render() {
        const { mobile, className, responseError, headerMenu } = this.props;

        if (responseError || !headerMenu) return null;

        return <nav className={bem(null, null, className)}>{mobile ? this.renderMobileMenu() : this.renderDesktopMenu()}</nav>;
    }
}

Nav.defaultProps = {
    mobile: false,
    messages: {}
};

Nav.propTypes = {
    headerMenu: PropTypes.object.isRequired,
    messages: PropTypes.shape({
        nav: PropTypes.shape({
            myAccount: PropTypes.shape({
                text: PropTypes.func.isRequired,
                href: PropTypes.func.isRequired
            }),
            wishlist: PropTypes.shape({
                text: PropTypes.func.isRequired,
                href: PropTypes.func.isRequired
            }),
            signOut: PropTypes.shape({
                text: PropTypes.func.isRequired,
                href: PropTypes.func.isRequired
            }),
            register: PropTypes.shape({
                text: PropTypes.func.isRequired,
                href: PropTypes.func.isRequired
            }),
            signIn: PropTypes.shape({
                text: PropTypes.func.isRequired,
                href: PropTypes.func.isRequired
            })
        }).isRequired,
        courtesyNav: PropTypes.shape({
            customerServiceInfo: PropTypes.shape({
                text: PropTypes.func.isRequired,
                href: PropTypes.func.isRequired
            }),
            createReturn: PropTypes.shape({
                text: PropTypes.func.isRequired,
                href: PropTypes.func.isRequired
            }),
            trackOrder: PropTypes.shape({
                text: PropTypes.func.isRequired,
                href: PropTypes.func.isRequired
            }),
            myOrders: PropTypes.shape({
                text: PropTypes.func.isRequired,
                href: PropTypes.func.isRequired
            })
        }).isRequired
    }).isRequired,
    mobile: PropTypes.bool,
    className: PropTypes.string,
    config: PropTypes.object,
    logOut: PropTypes.func,
    isSignedIn: PropTypes.bool,
    locale: PropTypes.shape({
        id: PropTypes.string.isRequired
    }).isRequired,
    responseError: PropTypes.bool,
    minimisedHeaderModifier: PropTypes.string,
    onInteraction: PropTypes.func
};

export default Nav;
